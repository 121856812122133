<script>
import AIContentHistory from '@app/components/AIContentHistory.vue';

export default {
  head: {
    title: 'Historique du Copywriter IA',
  },
  components: {
    AIContentHistory,
  },
  provide() {
    return { viewerHasInsertCta: false };
  },
};
</script>

<template>
  <section>
    <h1 class="title is-4">
      Historique de vos contenus générés
    </h1>
    <p>
      Retrouvez ici, à tout moment, vos contenus générés par notre Copywriter IA. <br>
      Copiez ces contenus et collez-les dans les champs de descriptions de vos formations.
    </p>
    <AIContentHistory />
  </section>
</template>
